import React, {FC, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {
    deleteTicketTemplateDescription,
    deleteTicketTemplateTitle,
    getTicketTemplateDetails,
    getTicketTemplateMode,
    TicketTemplateDetails,
    TicketTemplateState,
    updateTicketTemplateOrder, updateTicketTemplatePrice,
    updateTicketTemplateState,
    upsertTicketTemplateDescription,
    upsertTicketTemplateTitle
} from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import {MoneyAmount} from "../../components/common/MoneyAmount/MoneyAmount";
import {Button, ButtonGroup, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Sidebar} from "../../components/common/Sidebar/Sidebar";
import {
    hiddenTranslatedStringModal,
    Inputs,
    TranslatedStringModal, TranslatedStringModalProps
} from "../../components/catalog/TranslatedStringModal/TranslatedStringModal";
import {Toast} from "../../components/common/Toast/Toast";
import {ConfirmModal, hiddenModal} from "../../components/common/ConfirmModal/ConfirmModal";
import {
    hiddenDisplayOrderModal, TicketTemplateDisplayOrderModal,
    TicketTemplateDisplayOrderModalProps
} from "../../components/catalog/TicketTemplateDisplayOrderModal/TicketTemplateDisplayOrderModal";
import {
    hiddenPriceModal,
    TicketTemplatePriceModal, TicketTemplatePriceModalProps
} from "../../components/catalog/TicketTemplatePriceModal/TicketTemplateDisplayOrderModal";
import {DurationHoursDiv} from "../../components/catalog/DurationHoursDiv/DurationHoursDiv";
import {DurationDaysDiv} from "../../components/catalog/DurationDaysDiv/DurationDaysDiv";
import {TripsDiv} from "../../components/catalog/TripsDiv/TripsDiv";

interface ToastProps {
    show: boolean
    text: string
}

export const TicketTemplatePage: FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {networkId, ticketTemplateId} = useParams<{ networkId: string, ticketTemplateId: string }>();
    const [ticketTemplateDetails, setTicketTemplateDetails] = useState<TicketTemplateDetails | null>(null);
    const [confirmModal, setConfirmModal] = useState(hiddenModal());
    const [toastProps, setToastProps] = useState<ToastProps>({show: false, text: ''})
    const [translatedStringModal, setTranslatedStringModal] = useState<TranslatedStringModalProps>(hiddenTranslatedStringModal());
    const [displayOrderModal, setDisplayOrderModal] = useState<TicketTemplateDisplayOrderModalProps>(hiddenDisplayOrderModal());
    const [priceModal, setPriceModal] = useState<TicketTemplatePriceModalProps>(hiddenPriceModal());

    const getStateButton = (
        ticketTemplateDetails: TicketTemplateDetails,
        state: TicketTemplateState,
        variant: string) => {
        return (
            <Button
                variant={ticketTemplateDetails.state === state ? variant : `outline-${variant}`}
                onClick={() => confirm(
                    t('confirmUpdateTicketTemplateStateTitle'),
                    t('confirmUpdateTicketTemplateStateBody'),
                    () => updateTicketTemplateState(ticketTemplateId, state).then(() => refreshPage()))}>
                {t(state)}
            </Button>
        );
    }

    useEffect(() => {
        getTicketTemplateDetails(ticketTemplateId).then(template => setTicketTemplateDetails(template));
    }, [ticketTemplateId]);

    if (!ticketTemplateDetails)
        return <Skeleton/>

    const ticketTemplateMode = getTicketTemplateMode(ticketTemplateDetails);

    const refreshPage = () => history.go(0);

    const goToCatalog = () => history.push(`/networks/${networkId}/catalog`);

    const handleUpsertTitleSubmit = (inputs: Inputs) =>
        upsertTicketTemplateTitle(ticketTemplateId, inputs.language, inputs.text).then(() => refreshPage())

    const handleUpsertDescriptionSubmit = (inputs: Inputs) =>
        upsertTicketTemplateDescription(ticketTemplateId, inputs.language, inputs.text).then(() => refreshPage())

    const confirm = (title: string, body: string, onConfirm: () => void) => {
        setConfirmModal({
            show: true,
            title: title,
            body: body,
            onConfirm: onConfirm,
            onCancel: () => setConfirmModal(hiddenModal())
        });
    }

    const addTranslatedString = (title: string, fieldName: string, onSubmit: (inputs: Inputs) => void) => {
        setTranslatedStringModal({
            show: true,
            title: title,
            fieldName: fieldName,
            onClose: () => setTranslatedStringModal(hiddenTranslatedStringModal()),
            onSubmit: onSubmit,
        } as TranslatedStringModalProps);
    };

    const updateTranslatedString = (title: string, fieldName: string, language: string, text: string, onSubmit: (inputs: Inputs) => void) => {
        setTranslatedStringModal({
            show: true,
            title: title,
            fieldName: fieldName,
            language: language,
            text: text,
            onClose: () => setTranslatedStringModal(hiddenTranslatedStringModal()),
            onSubmit: onSubmit,
        } as TranslatedStringModalProps);
    };

    const onTitleDelete = (languageCode: string) =>
        confirm(
            t('confirmDeleteTicketTemplateTitleTitle'),
            t('confirmDeleteTicketTemplateTitleBody'),
            () => deleteTicketTemplateTitle(ticketTemplateId, languageCode).then(() => refreshPage()));

    const onDescriptionDelete = (languageCode: string) =>
        confirm(
            t('confirmDeleteTicketTemplateDescriptionTitle'),
            t('confirmDeleteTicketTemplateDescriptionBody'),
            () => deleteTicketTemplateDescription(ticketTemplateId, languageCode).then(() => refreshPage()));


    return (
        <div className="row min-vh-100">
            <div className="col col-2">
                <Sidebar activeMenu="catalog"/>
            </div>
            <div className="col col-10" style={{marginTop: '1rem'}}>
                <div className="mt-3 mr-3">
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">{t('state')}</Form.Label>
                            <Col sm="10">
                                <ButtonGroup>
                                    {getStateButton(ticketTemplateDetails, 'active', 'primary')}
                                    {getStateButton(ticketTemplateDetails, 'hidden', 'warning')}
                                    {getStateButton(ticketTemplateDetails, 'disable', 'warning')}
                                    {getStateButton(ticketTemplateDetails, 'deleted', 'danger')}
                                </ButtonGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">
                                <span>{t('title')}</span>
                                <Button
                                    className="ml-2"
                                    size="sm"
                                    variant="outline-success"
                                    onClick={() => addTranslatedString(
                                        t('addTitle'), t('title'), handleUpsertTitleSubmit)}>
                                    {t('add')}
                                </Button>
                            </Form.Label>
                            <Col sm="10">
                                <table className="table table-sm">
                                    <tbody>
                                    {ticketTemplateDetails.titles.map(title => {
                                        return (
                                            <tr key={`title-${title.languageCode}`}>
                                                <td>{title.languageCode}</td>
                                                <td>{title.text}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button
                                                            size="sm"
                                                            variant="outline-warning"
                                                            onClick={() => updateTranslatedString(
                                                                t('addTitle'),
                                                                t('title'),
                                                                title.languageCode,
                                                                title.text,
                                                                handleUpsertTitleSubmit)}>
                                                            {t('edit')}
                                                        </Button>
                                                        <Button size="sm" variant="outline-danger"
                                                                onClick={() => onTitleDelete(title.languageCode)}>
                                                            {t('delete')}
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">
                                <span>{t('description')}</span>
                                <Button
                                    className="ml-2"
                                    size="sm"
                                    variant="outline-success"
                                    onClick={() => addTranslatedString(
                                        t('addDescription'), t('description'), handleUpsertDescriptionSubmit)}>
                                    {t('add')}
                                </Button>
                            </Form.Label>
                            <Col sm="10">
                                <table className="table table-sm">
                                    <tbody>
                                    {ticketTemplateDetails.descriptions.map(description => {
                                        return (
                                            <tr key={`title-${description.languageCode}`}>
                                                <td>{description.languageCode}</td>
                                                <td>{description.text}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button
                                                            size="sm"
                                                            variant="outline-warning"
                                                            onClick={() => updateTranslatedString(
                                                                t('addDescription'),
                                                                t('description'),
                                                                description.languageCode,
                                                                description.text,
                                                                handleUpsertDescriptionSubmit)}>
                                                            {t('edit')}
                                                        </Button>
                                                        <Button size="sm" variant="outline-danger"
                                                                onClick={() => onDescriptionDelete(description.languageCode)}>
                                                            {t('delete')}
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">
                                <span>{t('productCode')}</span>
                            </Form.Label>
                            <Col sm="10">
                                <span>{ticketTemplateDetails.productCode}</span>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">{t('price')}</Form.Label>
                            <Col sm="10">
                                <MoneyAmount
                                    cents={ticketTemplateDetails.price}
                                    currencyCode={ticketTemplateDetails.currency}
                                    fractionDigits={2}/>
                                <Button
                                    variant="outline-warning"
                                    className="ml-2"
                                    size="sm"
                                    onClick={() => setPriceModal({
                                        show: true,
                                        initialValue: ticketTemplateDetails?.price,
                                        onCancel: () => setPriceModal(hiddenPriceModal()),
                                        onConfirm: inputs => updateTicketTemplatePrice(ticketTemplateId, inputs.price).then(() => goToCatalog())
                                    })}>{t('edit')}
                                </Button>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">{t('displayOrder')}</Form.Label>
                            <Col sm="10">
                                {ticketTemplateDetails.weight}
                                <Button
                                    variant="outline-warning"
                                    className="ml-2"
                                    size="sm"
                                    onClick={() => setDisplayOrderModal({
                                        show: true,
                                        initialValue: ticketTemplateDetails?.weight,
                                        onCancel: () => setDisplayOrderModal(hiddenDisplayOrderModal()),
                                        onConfirm: inputs => updateTicketTemplateOrder(ticketTemplateId, inputs.displayOrder).then(() => refreshPage())
                                    })}>{t('edit')}</Button>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2" className="text-right">{t('mode')}</Form.Label>
                            <Col sm="10">
                                <div>
                                    <ButtonGroup>
                                        <Button
                                            variant={ticketTemplateMode === 'DURATION_HOURS' ? 'primary' : 'secondary'}>
                                            {t('durationInHours')}
                                        </Button>
                                        <Button
                                            variant={ticketTemplateMode === 'DURATION_DAYS' ? 'outline-primary' : 'secondary'}>
                                            {t('durationInDays')}
                                        </Button>
                                        <Button
                                            variant={ticketTemplateMode === 'TRIPS' ? 'outline-primary' : 'secondary'}>
                                            {t('tripCount')}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                <div className="mt-2">
                                    {ticketTemplateDetails.durationHours
                                        ? <DurationHoursDiv durationHours={ticketTemplateDetails.durationHours}/>
                                        : null}
                                    {ticketTemplateDetails.durationDays
                                        ? <DurationDaysDiv durationDays={ticketTemplateDetails.durationDays}/>
                                        : null}
                                    {ticketTemplateDetails.trips
                                        ? <TripsDiv trips={ticketTemplateDetails.trips}/>
                                        : null}
                                </div>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>

                <Toast
                    show={toastProps.show}
                    text={toastProps.text}
                    onClose={() => setToastProps({show: false, text: ''})}/>

                <ConfirmModal
                    show={confirmModal.show}
                    title={confirmModal.title}
                    body={confirmModal.body}
                    onConfirm={confirmModal.onConfirm}
                    onCancel={confirmModal.onCancel}/>

                <TranslatedStringModal
                    show={translatedStringModal.show}
                    title={translatedStringModal.title}
                    fieldName={translatedStringModal.fieldName}
                    language={translatedStringModal.language}
                    text={translatedStringModal.text}
                    onClose={translatedStringModal.onClose}
                    onSubmit={translatedStringModal.onSubmit}/>

                <TicketTemplateDisplayOrderModal
                    show={displayOrderModal.show}
                    initialValue={displayOrderModal.initialValue}
                    onCancel={displayOrderModal.onCancel}
                    onConfirm={displayOrderModal.onConfirm}/>

                <TicketTemplatePriceModal
                    show={priceModal.show}
                    initialValue={priceModal.initialValue}
                    onCancel={priceModal.onCancel}
                    onConfirm={priceModal.onConfirm}/>
            </div>
        </div>
    );
}
