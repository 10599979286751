import {FC, useEffect, useState} from "react";
import './Sidebar.scss'
import {Network} from "../../../hooks/useNetworks";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import Skeleton from "react-loading-skeleton";
import {getNetwork} from "../../../utils/api";

type Menu = 'stats' | 'catalog';

export interface Props {
    activeMenu: Menu
}

export const Sidebar: FC<Props> = ({activeMenu}) => {
    const {networkId} = useParams<{ networkId: string }>();
    const history = useHistory();
    const {t} = useTranslation();
    const [network, setNetwork] = useState<Network | null>(null);

    const onMenuLinkClicked = (menu: Menu) => {
        history.push(`/networks/${networkId}/${menu}`);
    };

    useEffect(() => {
        getNetwork(networkId).then(n => setNetwork(n));
    }, [networkId]);

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 min-vh-100 sidebar-container">
            <div className="d-flex justify-content-center pt-3">
                {network
                    ? <img src={`https://cdn2.witick.io/logos/${network.reference}.png`} height="50" alt={network.name}/>
                    : <Skeleton height="50"/>}
            </div>
            <hr/>
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <a onClick={() => onMenuLinkClicked('stats')} className={'nav-link ' + (activeMenu === 'stats' ? 'active' : '')} aria-current="page">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-graph-up"
                            viewBox="0 0 16 16"
                            style={{marginRight: '.5rem'}}>
                            <path
                                fillRule="evenodd"
                                d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                        {t('statistics')}
                    </a>
                </li>
                <li>
                    <a onClick={() => onMenuLinkClicked('catalog')} className={'nav-link ' + (activeMenu === 'catalog' ? 'active' : '')}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-shop"
                            viewBox="0 0 16 16"
                            style={{marginRight: '.5rem'}}>
                            <path
                                d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
                        </svg>
                        {t('catalog')}
                    </a>
                </li>
            </ul>
        </div>
    );
}
