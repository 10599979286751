import {Button, Modal} from "react-bootstrap";
import React, {FC} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

export interface TranslatedStringModalProps {
    show: boolean
    title: string
    fieldName: string
    language?: string
    text?: string
    onClose: () => void
    onSubmit: (inputs: Inputs) => void
}

export const hiddenTranslatedStringModal = () => {
    return {
        show: false,
        title: '',
        fieldName: '',
        onClose: () => null,
        onSubmit: () => null
    }
}

export type Inputs = {
    language: string
    text: string
}

export const TranslatedStringModal: FC<TranslatedStringModalProps> =
    ({show, title, fieldName, language, text, onClose, onSubmit}) => {

        const {t} = useTranslation();
        const {register, handleSubmit, watch, formState: {errors}} = useForm<Inputs>();

        return (
            <Modal show={show} onHide={onClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="languageInput">{t('language')}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="languageInput"
                                aria-describedby="languageHelp"
                                placeholder="fr"
                                {...register("language", {value:language, required: true, minLength: 2, maxLength: 2})}/>

                            <small id="languageHelp" className="form-text text-muted">
                                {t('languageInputHelp')}
                            </small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="textInput">{fieldName}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="textInput"
                                placeholder="..."
                                {...register("text", {value: text, required: true})}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    };
