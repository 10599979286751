import {Network} from "../../hooks/useNetworks";
import React, {FC, useState} from "react";
import {RevenueDailyHistoryChartCard} from "../../components/stats/RevenueDailyHistoryChartCard/RevenueDailyHistoryChartCard";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {DateRange} from "../../components/common/DateRangeSelect/DateRangeSelect";
import {Button} from "react-bootstrap";
import {DateRangeModal} from "../../components/common/DateRangeModal/DateRangeModal";
import {useTranslation} from "react-i18next";
import {RevenueOverviewCard} from "../../components/stats/RevenueOverviewCard/RevenueOverviewCard";
import {RevenueByProductPieChartCard} from "../../components/stats/RevenueByProductPieChartCard/RevenueByProductPieChartCard";
import {ValidationOverviewCard} from "../../components/stats/ValidationOverviewCard/ValidationOverviewCard";
import {ValidationDailyHistoryChartCard} from "../../components/stats/ValidationDailyHistoryChartCard/ValidationDailyHistoryChartCard";
import {ValidationByProductPieChartCard} from "../../components/stats/ValidationByProductPieChartCard/ValidationByProductPieChartCard";
import {useParams} from "react-router";
import {Sidebar} from "../../components/common/Sidebar/Sidebar";

const initialDateRange = (): DateRange => {
    const start = new Date();
    start.setDate(start.getDate() - 7);

    return {
        startDate: start,
        endDate: new Date()
    }
}

export const NetworkStatisticsPage: FC = () => {
    const {networkId} = useParams<{ networkId: string }>();
    const [dateRange, setDateRange] = useState(initialDateRange());
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const {t} = useTranslation();

    const handleDateRangeSelected = (dateRange: DateRange) => {
        setDateRange(dateRange);
        setShowDateRangeModal(false);
    }

    const dateRangeLabel = (dateRange: DateRange) => {
        return `${t('rangeFrom')} ${dateRange.startDate.toLocaleDateString()} ${t('rangeTo')} ${dateRange.endDate.toLocaleDateString()}`
    }

    return (
        <div className="row min-vh-100">
            <div className="col col-2">
                <Sidebar activeMenu="stats" />
            </div>
            <div className="col" style={{marginTop: '1rem'}}>
                <div className="col d-flex justify-content-end">
                    <Button variant="primary" onClick={() => setShowDateRangeModal(true)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-calendar-range"
                            viewBox="0 0 16 16"
                            style={{marginRight: '.5rem'}}>
                            <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1zM1 9h4a1 1 0 0 1 0 2H1V9z"/>
                            <path
                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg>
                        {dateRangeLabel(dateRange)}
                    </Button>

                    <DateRangeModal
                        show={showDateRangeModal}
                        dateRange={dateRange}
                        onDateRangeSelected={handleDateRangeSelected}/>
                </div>
                <div className="row mt-3 mr-3">
                    <div className="col-md-3">
                        <RevenueOverviewCard networkId={networkId} dateRange={dateRange} />
                    </div>
                    <div className="col-md-3">
                        <ValidationOverviewCard networkId={networkId} dateRange={dateRange} />
                    </div>
                </div>
                <div className="row mt-3 mr-3">
                    <div className="col-md-8">
                        <RevenueDailyHistoryChartCard networkId={networkId} dateRange={dateRange}/>
                    </div>
                    <div className="col-md-4">
                        <RevenueByProductPieChartCard networkId={networkId} dateRange={dateRange}/>
                    </div>
                </div>

                <div className="row mt-3 mr-3">
                    <div className="col-md-8">
                        <ValidationDailyHistoryChartCard networkId={networkId} dateRange={dateRange}/>
                    </div>
                    <div className="col-md-4">
                        <ValidationByProductPieChartCard networkId={networkId} dateRange={dateRange}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
