import React, {FC} from "react";
import {Button} from "react-bootstrap";
import {DurationDays, DurationHours} from "../../../utils/api";
import {useTranslation} from "react-i18next";

export interface DurationDaysDivProps {
    durationDays: DurationDays
}

export const DurationDaysDiv:FC<DurationDaysDivProps> = ({durationDays}) => {
    const {t} = useTranslation();

    return (
        <div className="mt-2">
            <div className="ml-2">
                <div>
                    <span>{durationDays.days} {t('days')}</span>
                </div>
            </div>
        </div>
    );
};
