import React, {FC} from "react";
import {useForm} from "react-hook-form";
import {DurationHours} from "../../../utils/api";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export interface TicketTemplateDurationHoursModalProps {
    show: boolean
    onConfirm: (inputs: DurationHoursInputs) => void
    onClose: () => void
}

export type DurationHoursInputs = {
    productCode: number
    hours: number
}

export const hiddenDurationHoursModal = (): TicketTemplateDurationHoursModalProps => {
    return {
        show: false,
        onConfirm: () => null,
        onClose: () => null
    };
};

export const TicketTemplateDurationHoursModal: FC<TicketTemplateDurationHoursModalProps> =
    ({show, onConfirm, onClose}) => {

        const {t} = useTranslation();
        const {register, handleSubmit, watch, formState: {errors}} = useForm<DurationHoursInputs>();

        return (
            <Modal show={show} onHide={onClose}>
                <form onSubmit={handleSubmit(onConfirm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('createTicketTemplateDurationHours')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="productCode">{t('productCode')}</label>
                            <input
                                id="productCode"
                                className="form-control"
                                type="number"
                                {...register("productCode", {required: true,})}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="hours">{t('durationInHours')}</label>
                            <input
                                id="hours"
                                className="form-control"
                                type="number"
                                {...register("hours", {required: true,})}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    };
