import React, {FC, useEffect, useState} from "react";
import './NextDeparturesPage.css';
import {NearbyStation} from "../../components/poc/NearbyStation/NearbyStation";
import {getNearbyStations, StationView} from "../../utils/api";
import Skeleton from "react-loading-skeleton";

export const NextDeparturesPage: FC = () => {

    const [loading, setLoading] = useState(false);
    const [nearbyStations, setNearbyStations] = useState<StationView[]>([]);

    const distance = 200;

    useEffect(() => {
        const load = (lat: number, lon: number, distance: number) => {
            getNearbyStations(lat, lon, distance).then(stations => {
                setLoading(false);
                setNearbyStations(stations);
            });
        };

        setLoading(true);

        navigator.geolocation.getCurrentPosition(position => {
            load(position.coords.latitude, position.coords.longitude, distance);
        }, positionError => {
            load(44.844747, -0.589054, distance);
        });

    }, [distance]);

    return (
        <div className="container">
            <div className="row h-75">
                <div className="col-sm-12 my-auto">
                    <div className="w-25 mx-auto">
                        {loading
                            ? <Skeleton/>
                            : nearbyStations.map(nearbyStation => {
                                return <NearbyStation station={nearbyStation}/>
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}
