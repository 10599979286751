import {Button, Modal} from "react-bootstrap";
import React, {FC} from "react";

export interface ConfirmModalProps {
    show: boolean
    title: string
    body: string
    onConfirm: () => void
    onCancel: () => void
}

export const hiddenModal = (): ConfirmModalProps => {
    return {
        show: false,
        title: '',
        body: '',
        onConfirm: () => null,
        onCancel: () => null
    }
}

export const ConfirmModal: FC<ConfirmModalProps> = ({show, title, body, onConfirm, onCancel}) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
