import React, {FC, useState} from "react";
import {ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {
    hiddenDurationHoursModal,
    TicketTemplateDurationHoursModal,
    TicketTemplateDurationHoursModalProps
} from "../TicketTemplateDurationHoursModal/TicketTemplateDurationHoursModal";
import {createTicketTemplateDurationHours, createTicketTemplateTrips} from "../../../utils/api";
import {useHistory} from "react-router";
import {
    hiddenTripsModal,
    TicketTemplateTripsModal,
    TicketTemplateTripsModalProps
} from "../TicketTemplateTripsModal/TicketTemplateTripsModal";

export interface CreateTicketTemplateButtonProps {
    networkId: string
}

export const CreateTicketTemplateButton: FC<CreateTicketTemplateButtonProps> = ({networkId}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [durationHoursModal, setDurationHoursModal] = useState<TicketTemplateDurationHoursModalProps>(hiddenDurationHoursModal());
    const [tripsModal, setTripsModal] = useState<TicketTemplateTripsModalProps>(hiddenTripsModal());

    const displayDurationHoursModal = () => {
        setDurationHoursModal({
            show: true,
            onConfirm: inputs => createTicketTemplateDurationHours(networkId, inputs.productCode, inputs.hours, "AT_USE")
                .then(ticketTemplateId => history.push(`/networks/${networkId}/ticket-templates/${ticketTemplateId}`)),
            onClose: () => setDurationHoursModal(hiddenDurationHoursModal())
        });
    };

    const displayTripsModal = () => {
        setTripsModal({
            show: true,
            onConfirm: inputs => createTicketTemplateTrips(networkId, inputs.productCode, inputs.tripCount)
                .then(ticketTemplateId => history.push(`/networks/${networkId}/ticket-templates/${ticketTemplateId}`)),
            onClose: () => setTripsModal(hiddenTripsModal())
        });
    };

    return (
        <div>
            <DropdownButton as={ButtonGroup} title={t('addTicketTemplate')} className="btn-success">
                <Dropdown.Item onClick={() => displayDurationHoursModal()}>
                    {t('durationInHours')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => displayTripsModal()}>
                    {t('trips')}
                </Dropdown.Item>
            </DropdownButton>

            <TicketTemplateDurationHoursModal
                show={durationHoursModal.show}
                onConfirm={durationHoursModal.onConfirm}
                onClose={durationHoursModal.onClose}/>

            <TicketTemplateTripsModal
                show={tripsModal.show}
                onConfirm={tripsModal.onConfirm}
                onClose={tripsModal.onClose}/>
        </div>
    );
};
