import {FC} from "react";
import {Toast as BootstrapToast} from "react-bootstrap";

export interface Props {
    show: boolean
    text: string
    onClose: () => void
}

export const Toast: FC<Props> = ({show, text, onClose}) => {
    return (
        <BootstrapToast show={show} delay={3000} autohide onClose={onClose}>
            <BootstrapToast.Header>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">Bootstrap</strong>
                <small>11 mins ago</small>
            </BootstrapToast.Header>
            <BootstrapToast.Body>{text}</BootstrapToast.Body>
        </BootstrapToast>
    );
};
