import {FC, useEffect, useState} from "react";
import {DepartureView, getNextDepartures, StationView} from "../../../utils/api";
import Skeleton from "react-loading-skeleton";
import {format} from "date-fns";

export interface Props {
    station: StationView
}

export const NearbyStation: FC<Props> = ({station}) => {

    const [loading, setLoading] = useState(false);
    const [nextDepartures, setNextDepartures] = useState<DepartureView[]>([]);

    useEffect(() => {
        setLoading(true);
        getNextDepartures(station.id).then(departures => {
            setLoading(false);
            departures.sort((a, b) => new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime());
            setNextDepartures(departures)
        });
    }, [station]);

    const formatColor = (color: string): string => {
        return "#" + color.substr(3, 8);
    }

    return (
        <div>
            <h2>{station.name}</h2>
            {loading ? <Skeleton/> : nextDepartures.length === 0 ? <div>---</div> : nextDepartures.map(nextDeparture => {
                return (
                    <div>
                        <span>
                        <span
                            style={{
                                color: formatColor(nextDeparture.route.textColor),
                                backgroundColor: formatColor(nextDeparture.route.color)}}>
                            {nextDeparture.route.shortName}
                        </span>
                        <span> {'-->'} {nextDeparture.headsign}</span>
                        </span>
                        <p><code>{nextDeparture.source}</code>: {format(new Date(nextDeparture.departureDate), 'dd/MM/yyyy HH:mm')}</p>
                    </div>
                );
            })}
        </div>
    );
};
