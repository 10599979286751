import {useEffect, useState} from "react";
import {useAuthenticatedUser} from "./useAuthenticatedUser";
import {getActiveNetworks, getNetworkRoles, NetworkRole} from "../utils/api";

export interface Network {
    id: string
    name: string
    reference: string
}

const isNetworkAdmin = (network: Network, networkRoles: NetworkRole[]): boolean => {
    return networkRoles.find(networkRole =>
        networkRole.networkId === network.id && networkRole.role === 'ADMIN') !== undefined;
}

export function useNetworks(): Network[] {
    const {authenticatedUser} = useAuthenticatedUser();

    const [networks, setNetworks] = useState<Network[]>([]);

    useEffect(() => {
        if (!authenticatedUser)
            return;

        getNetworkRoles(authenticatedUser.userId)
            .then(roles => {
                getActiveNetworks()
                    .then(activeNetworks => {
                        setNetworks(activeNetworks.filter(network => isNetworkAdmin(network, roles)));
                    });
            })
            .catch(reason => {
                console.error(reason);
            });
    }, [authenticatedUser]);

    return networks;
}
