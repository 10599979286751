import {AuthenticationLog, getAuthenticationLogs, getUserProfile, UserProfile} from "../../utils/api";
import {FC, useEffect, useState} from "react";
import {format} from 'date-fns'
import {useParams} from "react-router";
import Skeleton from "react-loading-skeleton";

export const UserOverviewPage: FC = () => {
    const {userId} = useParams<{ userId: string }>();
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [authenticationLogs, setAuthenticationLogs] = useState<AuthenticationLog[]>();

    useEffect(() => {
        getUserProfile(userId).then(profile => setUserProfile(profile));
        getAuthenticationLogs(userId).then(logs => setAuthenticationLogs(logs));
    }, [userId])

    return (
        <div className="container pt-2">
            <h2>
                {userProfile
                    ? <span>{userProfile.email}</span>
                    : <Skeleton/>}

            </h2>
            <table className="table table-sm table-borderless">
                {authenticationLogs?.map(authenticationLog => {
                    return (
                        <tr>
                            <td>{authenticationLog.authenticationType}</td>
                            <td>
                                {authenticationLog.authenticationResultType}
                            </td>
                            <td>{format(new Date(authenticationLog.date), 'dd/MM/yyyy HH:mm')}</td>
                            <td><code>{authenticationLog.userAgent}</code></td>
                        </tr>
                    );
                })}
            </table>
        </div>
    );
};
