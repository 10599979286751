import React, {FC} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

export interface TicketTemplatePriceModalProps {
    show: boolean
    initialValue: number
    onCancel: () => void
    onConfirm: (inputs: Inputs) => void
}

export const hiddenPriceModal = (): TicketTemplatePriceModalProps => {
    return {
        show: false,
        initialValue: 1,
        onCancel: () => null,
        onConfirm: () => null
    }
}

export type Inputs = {
    price: number
}

export const TicketTemplatePriceModal: FC<TicketTemplatePriceModalProps> =
    ({show, initialValue, onCancel, onConfirm}) => {

        const {t} = useTranslation();
        const {register, handleSubmit, watch, formState: {errors}} = useForm<Inputs>();

        const onConfirmProxy = (inputs: Inputs) => {
            onConfirm({
                price: inputs.price * 100
            });
        };

        return (
            <Modal show={show} onHide={onCancel}>
                <form onSubmit={handleSubmit(onConfirmProxy)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('updatePrice')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">€</div>
                            </div>
                            <input
                                className="form-control"
                                type="number"
                                step=".01"
                                {...register("price", {value: initialValue / 100, required: true,})}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            OK
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
