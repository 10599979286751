import React, {FC} from "react";
import {Button} from "react-bootstrap";
import {DurationHours} from "../../../utils/api";
import {useTranslation} from "react-i18next";

export interface DurationHoursDivProps {
    durationHours: DurationHours
}

export const DurationHoursDiv:FC<DurationHoursDivProps> = ({durationHours}) => {
    const {t} = useTranslation();

    return (
        <div className="mt-2">
            <div>
                <span>{durationHours.hours}h</span>
            </div>
            <div>
                <span>{t('trigger')}: {t(durationHours.activationMode)}</span>
            </div>
        </div>
    );
};
