import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getRevenueByProduct} from "../../../utils/api";
import {DateRange} from "../../common/DateRangeSelect/DateRangeSelect";
import {PieChartData, PieChartCard} from "../../common/PieChartCard/PieChartCard";

interface Props {
    networkId: string
    dateRange: DateRange
}

export const RevenueByProductPieChartCard: FC<Props> = ({networkId, dateRange}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<PieChartData[]>([]);
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);

        getRevenueByProduct(networkId, dateRange.startDate, dateRange.endDate)
            .then(revenueByProductData => {
                setData(revenueByProductData.map(data => {
                    return {
                        name: data.productName,
                        value: data.revenue / 100
                    } as PieChartData;
                }));

                setLoading(false);
            })
            .catch(reason => {
                console.error(reason);
                setLoading(false);
            });
    }, [networkId, dateRange]);

    return <PieChartCard title={t('revenueByProduct')} unit="€" data={data} loading={loading}/>;
};
