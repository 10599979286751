import React, {FC, useEffect, useState} from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {useTranslation} from "react-i18next";
import {getNetworkCatalog, NetworkCatalog} from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import {MoneyAmount} from "../../components/common/MoneyAmount/MoneyAmount";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router";
import {Sidebar} from "../../components/common/Sidebar/Sidebar";
import {CreateTicketTemplateButton} from "../../components/catalog/CreateTicketTemplateButton/CreateTicketTemplateButton";

export const NetworkCatalogPage: FC = () => {
    const {networkId} = useParams<{ networkId: string }>();
    const history = useHistory();
    const {t} = useTranslation();
    const [catalog, setCatalog] = useState<NetworkCatalog | null>(null);

    const onCatalogItemClick = (ticketTemplateId: string) => {
        history.push(`/networks/${networkId}/ticket-templates/${ticketTemplateId}`)
    }

    useEffect(() => {
        getNetworkCatalog(networkId).then(c => setCatalog(c));
    }, [networkId])

    if (!catalog)
        return <Skeleton/>;

    return (
        <div className="row min-vh-100">
            <div className="col col-2">
                <Sidebar activeMenu="catalog"/>
            </div>
            <div className="col" style={{marginTop: '1rem'}}>
                <h2>{t('catalog')}</h2>

                <CreateTicketTemplateButton networkId={networkId}/>

                <table className="table">

                    <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('price')}</th>
                        <th/>
                    </tr>
                    </thead>

                    {catalog.ticketTemplates.map(template => {
                        return (
                            <tr>
                                <td>{template.title}</td>
                                <td>
                                    <MoneyAmount
                                        witickAmount={template.price}
                                        currencyCode={template.currency}
                                        fractionDigits={2}/>
                                </td>
                                <td><Button onClick={() => onCatalogItemClick(template.id)}>{t('view')}</Button></td>
                            </tr>
                        );
                    })}

                </table>
            </div>
        </div>
    );
};
