import {FC} from "react";

export class Currency {

    public static EURO = new Currency('€', 'EUR');

    public constructor(public readonly symbol: string, public readonly code: string) {
        this.symbol = symbol;
        this.code = code;
    }
}

interface Props {
    witickAmount?: number
    cents?: number
    currencyCode: string
    fractionDigits: number
}

export const MoneyAmount: FC<Props> =
    ({witickAmount, cents, currencyCode, fractionDigits}) => {

        const formatter = Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });

        const formatted = witickAmount
            ? formatter.format(witickAmount / 10000)
            : formatter.format(cents ? cents / 100 : -1);

        return <span>{formatted}</span>;
    }
