import {Button, Modal} from "react-bootstrap";
import {DateRange, DateRangeSelect} from "../DateRangeSelect/DateRangeSelect";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";

interface DateRangeModalProps {
    show: boolean
    dateRange: DateRange
    onDateRangeSelected: (dateRage: DateRange) => void
}

export const DateRangeModal: FC<DateRangeModalProps> = ({show, dateRange, onDateRangeSelected}) => {
    const {t} = useTranslation();
    const [selectedDateRange, setSelectedDateRange] = useState(dateRange);


    const handleClose = () => {
        onDateRangeSelected(selectedDateRange);
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('period')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <DateRangeSelect onSelect={setSelectedDateRange}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
