import {FC, useRef, useState} from "react";
import debounce from 'lodash.debounce';
import './UserSearchPage.css';
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {getUsers, User} from "../../utils/api";
import {format} from "date-fns";
import Skeleton from "react-loading-skeleton";
import {LinkContainer} from "react-router-bootstrap";

export const UserSearchPage: FC = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);

    const debouncedSearch = useRef(debounce(nextEmail => {
        getUsers(nextEmail, 10)
            .then(users => setUsers(users))
            .then(() => setLoading(false))
            .catch(reason => console.log(reason));
    }, 1000)).current;

    const onEmailChange = (email: string) => {
        setLoading(true);
        setUsers([]);

        if (email && email.length > 3) {
            debouncedSearch(email);
        }
    };

    return (
        <div className="container">
            <div className="row h-75">
                <div className="col-sm-12 my-auto">
                    <div className="w-50 mx-auto">
                        <div className="d-flex justify-content-center pt-3 mb-5">
                            <h2>{t('searchUser')}</h2>
                        </div>

                        <Form>
                            <Form.Group controlId="email">
                                <Form.Control
                                    type="email"
                                    autoComplete="off"
                                    placeholder={t('searchUser')}
                                    required
                                    autoFocus
                                    onChange={event => onEmailChange(event.target.value)}/>
                            </Form.Group>
                        </Form>
                        {loading ? <Skeleton height={100}/> : null}
                        {users.length < 1 ? null :
                            <table className="table table-sm">
                                <thead>
                                <tr>
                                    <th>{t('signUpDate')}</th>
                                    <th>{t('emailAddress')}</th>
                                    <th/>
                                </tr>
                                </thead>
                                {users.map(user => {
                                    return (
                                        <tr>
                                            <td>{format(new Date(user.createdAt), 'dd/MM/yyyy HH:mm')}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                <LinkContainer to={`/users/${user.id}/overview`}>
                                                    <Button size="sm">{t('view')}</Button>
                                                </LinkContainer>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
