import React, {FC} from "react";
import {useForm} from "react-hook-form";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export interface TicketTemplateTripsModalProps {
    show: boolean
    onConfirm: (inputs: TripsInputs) => void
    onClose: () => void
}

export type TripsInputs = {
    productCode: number
    tripCount: number
}

export const hiddenTripsModal = (): TicketTemplateTripsModalProps => {
    return {
        show: false,
        onConfirm: () => null,
        onClose: () => null
    };
};

export const TicketTemplateTripsModal: FC<TicketTemplateTripsModalProps> =
    ({show, onConfirm, onClose}) => {

        const {t} = useTranslation();
        const {register, handleSubmit, watch, formState: {errors}} = useForm<TripsInputs>();

        return (
            <Modal show={show} onHide={onClose}>
                <form onSubmit={handleSubmit(onConfirm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('createTicketTemplateTrips')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="productCode">{t('productCode')}</label>
                            <input
                                id="productCode"
                                className="form-control"
                                type="number"
                                {...register("productCode", {required: true,})}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="hours">{t('tripCount')}</label>
                            <input
                                id="hours"
                                className="form-control"
                                type="number"
                                {...register("tripCount", {required: true,})}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    };
