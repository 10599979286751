import {FC} from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useTranslation} from "react-i18next";
import {Card} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export interface DailyData {
    date: Date
    dailyValue: number
    movingAverage: number
}

interface Props {
    title: string
    dailyValueName: string
    unit: string
    loading: boolean
    data: DailyData[]
}

export const DailyHistoryChartCard: FC<Props> = ({title, unit, dailyValueName, data, loading}) => {
    const {t} = useTranslation();

    return (
        <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
                {loading
                    ? <Skeleton height={200}/>
                    : <ResponsiveContainer width="100%" height={200}>
                        <ComposedChart data={data}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Bar name={dailyValueName} dataKey="dailyValue" unit={unit} fill="#1EACE3"/>

                            <Line name={t('movingAverage')}
                                  type="monotone"
                                  dot={false}
                                  unit={unit}
                                  dataKey="movingAverage"
                                  stroke="#0382B3"
                                  strokeWidth={2}/>

                            <XAxis dataKey="date" hide={true}/>
                            <YAxis unit={unit}/>
                            <Legend/>
                            <Tooltip/>
                        </ComposedChart>
                    </ResponsiveContainer>}

            </Card.Body>
        </Card>
    );
};
