import {Cell, Pie, ResponsiveContainer, PieChart, Tooltip, TooltipProps, Legend} from "recharts";
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";
import {Card} from "react-bootstrap";
import {FC} from "react";
import Skeleton from "react-loading-skeleton";

const PRIMARY_COLORS = ['#1EACE3', '#F3AD3C', '#33CD54', '#FA6B6B']
const SECONDARY_COLORS = ['#0382B3', '#606F7A', '#00871D', '#D00000', '#B76A01'];

export interface PieChartData {
    name: string
    value: number
}

interface Props {
    title: string
    unit: string
    loading: boolean
    data: PieChartData[]
}

const stringHash = (str: string): number => {
    let hash = 0, i, chr;

    if (str.length === 0) return hash;

    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}

const getAvailableColors = (primaryColors: string[], secondaryColors: string[]): string[] => {
    if (primaryColors.length > 0)
        return primaryColors;

    if (secondaryColors.length > 0)
        return secondaryColors;

    return [...PRIMARY_COLORS]; // fallback if no color remaining
}

export const PieChartCard: FC<Props> = ({title, unit, loading, data}) => {
    const availablePrimaryColors = [...PRIMARY_COLORS];
    const availableSecondaryColors = [...SECONDARY_COLORS];

    const labelColors: Map<string, string> = new Map<string, string>();

    data
        .map(dataPoint => dataPoint.name)
        .sort()
        .forEach((name, index) => {
            const availableColors = getAvailableColors(availablePrimaryColors, availableSecondaryColors);
            const colorIndex = Math.abs(stringHash(name)) % availableColors.length;
            const color = availableColors[colorIndex];
            availableColors.splice(colorIndex, 1);
            labelColors.set(name, color);
        })

    const CustomTooltip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
        if (active) {
            return (
                <Card>
                    <Card.Body>
                        <span className="label">{`${payload?.[0].name} : ${payload?.[0].value} ${unit}`}</span>
                    </Card.Body>
                </Card>
            );
        }

        return null;
    };

    return (
        <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
                {loading
                    ? <Skeleton height={200}/>
                    : <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                            <Pie
                                data={data}
                                innerRadius={60}
                                outerRadius={100}
                                nameKey="name"
                                dataKey="value">

                                {data.map((entry, index) => <Cell fill={labelColors.get(entry.name)}/>)}

                            </Pie>
                            <Legend layout="vertical" verticalAlign="top" align="right" />
                            <Tooltip content={<CustomTooltip/>}/>
                        </PieChart>
                    </ResponsiveContainer>}
            </Card.Body>
        </Card>
    );
};
