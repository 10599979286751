import React, {FC} from "react";
import {Button} from "react-bootstrap";
import {Trips} from "../../../utils/api";
import {useTranslation} from "react-i18next";

export interface TripsDivProps {
    trips: Trips
}

export const TripsDiv: FC<TripsDivProps> = ({trips}) => {
    const {t} = useTranslation();

    return (
        <div className="mt-2">
            <div>
                <div>
                    <span>{trips.count} {t('trips')}</span>
                </div>
            </div>
        </div>
    );
};
