import {DateRangePicker, OnChangeProps, RangeWithKey,} from "react-date-range";
import React, {FC, useState} from "react";
import {fr} from "date-fns/locale";

export interface DateRange {
    startDate: Date,
    endDate: Date
}

interface Range extends DateRange {
}

interface DateRangeSelectProps {
    onSelect: (dateRange: DateRange) => void
}

export const DateRangeSelect: FC<DateRangeSelectProps> = ({onSelect}) => {
    const [range, setRange] = useState({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    )

    const onChange = (e: OnChangeProps) => {
        if (e.hasOwnProperty('startDate')) {
            const range = e as Range;
            let startDate = range.startDate ? range.startDate : new Date();
            let endDate = range.endDate ? range.endDate : new Date();
            updateRange(startDate, endDate);
        } else if (e.hasOwnProperty('selection')) {
            const inter = e as { selection: RangeWithKey };
            const selection = inter.selection as RangeWithKey;
            const startDate = selection.startDate ? selection.startDate : new Date();
            const endDate = selection.endDate ? selection.endDate : new Date();
            updateRange(startDate, endDate);
        }
    }

    const updateRange = (startDate: Date, endDate: Date) => {
        setRange({
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        });

        onSelect({
            startDate: startDate,
            endDate: endDate
        });
    }

    return (
        <DateRangePicker
            locale={fr}
            ranges={[range]}
            onChange={onChange}
            //staticRanges={[]}
            //inputRanges={[]}
            maxDate={new Date()}
            showDateDisplay={true}
            color='#016A93'
            rangeColors={['#016A93']}
        />
    );
};
