import {FC, useEffect, useState} from "react";
import {DateRange} from "../../common/DateRangeSelect/DateRangeSelect";
import {useTranslation} from "react-i18next";
import {getDailyRevenueHistory} from "../../../utils/api";
import {DailyData, DailyHistoryChartCard} from "../DailyHistoryChartCard/DailyHistoryChartCard";

interface Props {
    networkId: string
    dateRange: DateRange
}

export const RevenueDailyHistoryChartCard: FC<Props> = ({networkId, dateRange}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DailyData[]>([]);
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);

        getDailyRevenueHistory(networkId, dateRange.startDate, dateRange.endDate)
            .then(dailyRevenueHistory => {
                setData(dailyRevenueHistory.data.map(data => {
                    return {
                        date: data.date,
                        dailyValue: data.revenue / 100,
                        movingAverage: data.movingAverage / 100
                    } as DailyData;
                }));

                setLoading(false);
            })
            .catch(reason => {
                console.log(reason);
                setData([]);
                setLoading(false);
            });
    }, [networkId, dateRange]);

    return (
        <DailyHistoryChartCard
            title={t('dailyRevenue')}
            dailyValueName={t('revenue')}
            unit="€"
            data={data}
            loading={loading}/>
    );
};
