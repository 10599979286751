import {AUTHENTICATED_USER_STORAGE_KEY} from "../constants";
import {AuthenticatedUser, renew} from "./api";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export const isAlmostExpired = (authenticatedUser: AuthenticatedUser) => {
    const safeMarginBeforeExpiryInSeconds = 60;
    let nowMillis = new Date().getTime() - (safeMarginBeforeExpiryInSeconds * 1000);
    let expiryMillis = authenticatedUser.expiry.getTime();
    return nowMillis > expiryMillis;
};

export const isExpired = (authenticatedUser: AuthenticatedUser) => {
    return new Date().getTime() > authenticatedUser.expiry.getTime();
};

export const getAuthenticatedUser = (): AuthenticatedUser | null => {
    const authenticatedUserString = sessionStorage.getItem(AUTHENTICATED_USER_STORAGE_KEY);

    if (authenticatedUserString) {
        const authenticatedUser = JSON.parse(authenticatedUserString) as AuthenticatedUser;
        authenticatedUser.expiry = new Date(authenticatedUser.expiry);
        return authenticatedUser;
    } else {
        return null;
    }
}

export const persistAuthenticatedUser = (authenticatedUser: AuthenticatedUser) => {
    sessionStorage.setItem(AUTHENTICATED_USER_STORAGE_KEY, JSON.stringify(authenticatedUser));
    console.log('Persisted authenticated user: ' + authenticatedUser.userId);
}

export const removePersistedAuthenticatedUser = () => {
    sessionStorage.removeItem(AUTHENTICATED_USER_STORAGE_KEY);
}

axios.interceptors.request.use(config => {
    const authenticatedUser = getAuthenticatedUser();

    if (authenticatedUser) {
        config.headers['x-user-id'] = authenticatedUser.userId;

        if (!config.headers.Authorization) { // to avoid overriding renewal
            config.headers.Authorization = 'Bearer ' + authenticatedUser.accessToken;

            if (isAlmostExpired(authenticatedUser)) {
                console.log('User is almost expired, renewing...');
                renew(authenticatedUser).then(renewedUser => persistAuthenticatedUser(renewedUser));
            }
        }
    }

    return config;
})

createAuthRefreshInterceptor(
    axios,
    failedRequest => {
        const authenticatedUser = getAuthenticatedUser();

        if (authenticatedUser && isExpired(authenticatedUser)) {
            console.log('User is expired, renewing...')

            return renew(authenticatedUser)
                .then(renewedUser => {
                    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + renewedUser.accessToken;
                    persistAuthenticatedUser(renewedUser);
                    return true;
                });
        } else {
            return Promise.resolve();
        }
    },
    {
        statusCodes: [401]
    });
