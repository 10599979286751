import axios, {AxiosResponse} from "axios";
import {Network} from "../hooks/useNetworks";

const apiBaseUri = process.env.REACT_APP_API_BASE_URI || 'https://not-configured';

type Role = 'ADMIN';

export type AuthenticationType = 'SIGN_IN' | 'TOKEN_REFRESH';
export type AuthenticationResultType = 'SUCCESS' | 'INVALID_CREDENTIALS' | 'USER_BLOCKED' | 'INVALID_REFRESH_TOKEN'

export interface Page<T> {
    content: T[]
}

export interface TranslatedString {
    languageCode: string
    text: string
}

export type TicketActivationMode = "AT_SALE" | "AT_USE";

export interface DurationHours {
    hours: number
    activationMode: TicketActivationMode
}

export interface DurationDays {
    days: number
}

export interface Trips {
    count: number
}

export type TicketTemplateState = "active" | "deleted" | "disable" | "hidden"

export interface TicketTemplateDetails {
    titles: TranslatedString[]
    descriptions: TranslatedString[]
    state: TicketTemplateState
    productCode: number
    price: number
    currency: string
    weight: number
    durationHours: DurationHours
    durationDays: DurationDays
    trips: Trips
}

export type TicketTemplateMode = 'DURATION_HOURS' | 'DURATION_DAYS' | 'TRIPS';

export const getTicketTemplateMode = (ticketTemplateDetails: TicketTemplateDetails): TicketTemplateMode => {
    if (ticketTemplateDetails.durationHours)
        return 'DURATION_HOURS';

    if (ticketTemplateDetails.durationDays)
        return 'DURATION_DAYS';

    if (ticketTemplateDetails.trips)
        return 'TRIPS';

    throw Error('Unknown ticket template mode');
}

export interface UserProfile {
    signUpDate: Date
    email: string
}

export interface AuthenticationLog {
    date: Date
    authenticationType: AuthenticationType
    authenticationResultType: AuthenticationResultType
    userAgent: string
}

export interface User {
    id: string
    createdAt: Date
    email: string
}

export interface NetworkCatalog {
    ticketTemplates: TicketTemplate[]
}

export interface DateInterval {
    start: Date
    end: Date
}

export interface TicketTemplate {
    id: string
    title: string
    description: string
    price: number
    currency: string
    purchaseDateIntervals: DateInterval[]
}

export interface NetworkRole {
    networkId: string
    role: Role
}

export interface ValidationByProductData {
    productName: string
    total: number
}

interface ValidationByProduct {
    data: ValidationByProductData[]
}

export interface ValidationOverview {
    total: number
}

interface DailyValidationHistoryData {
    date: Date
    total: number
    movingAverage: number
}

interface DailyValidationHistory {
    data: DailyValidationHistoryData[]
}

export interface RevenueByProductData {
    productName: string
    revenue: number
}

interface RevenueByProduct {
    data: RevenueByProductData[]
}

export interface RevenueOverview {
    revenue: number
}

interface DailyRevenueHistoryData {
    date: Date
    revenue: number
    movingAverage: number
}

interface DailyRevenueHistory {
    data: DailyRevenueHistoryData[]
}

interface AuthResponseBody {
    idUser: string
    accessToken: string
    refreshToken: string
    expiresIn: number
}

export interface AuthenticatedUser {
    userId: string
    email: string
    accessToken: string
    refreshToken: string
    expiry: Date
}

export interface StationView {
    id: string
    name: string
}

export interface RouteView {
    shortName: string
    longName: string
    color: string
    textColor: string
    type: string
}

export interface DepartureView {
    route: RouteView
    headsign: string
    departureDate: Date
    source: string
}

const dateToQueryParam = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`
};

export const getNearbyStations = (lat: number, lon: number, distance: number): Promise<StationView[]> => {
    return axios.get(apiBaseUri + `/nearby-stations?lat=${lat}&lon=${lon}&distance=${distance}`)
        .then(response => {
            const stationsPage = response.data as Page<StationView>;
            return stationsPage.content;
        });
}

export const getNextDepartures = (stopId: string): Promise<DepartureView[]> => {
    return axios.get(apiBaseUri + '/next-departures?stop-id=' + stopId)
        .then(response => {
            const departuresPage = response.data as Page<DepartureView>;
            return departuresPage.content;
        });
}

export const getActiveNetworks = (): Promise<Network[]> => {
    return axios.get(apiBaseUri + '/networks?state=active')
        .then(networksResponse => {
            const networksPage = networksResponse.data as Page<Network>;
            return networksPage.content;
        });
};

export const getNetworkRoles = (userId: string): Promise<NetworkRole[]> => {
    return axios.get<Page<NetworkRole>>(apiBaseUri + '/network-roles?user-id=' + userId)
        .then(networkRolesResponse => {
            const networkRolesPage = networkRolesResponse.data as Page<NetworkRole>;
            return networkRolesPage.content;
        })
};

export const getNetwork = (networkId: string): Promise<Network> => {
    return axios.get<Network>(apiBaseUri + `/networks/${networkId}`)
        .then(response => response.data as Network);
};

export const getValidationByProduct = (networkId: string, startDate: Date, endDate: Date): Promise<ValidationByProductData[]> => {
    return axios
        .get<ValidationByProduct>(apiBaseUri + '/stats/validation-by-product', {
            params: {
                'network-id': networkId,
                'start': dateToQueryParam(startDate),
                'end': dateToQueryParam(endDate)
            }
        })
        .then(response => response.data as ValidationByProduct)
        .then(revenueByProduct => revenueByProduct.data)
};

export const getValidationOverview = (networkId: string, startDate: Date, endDate: Date): Promise<ValidationOverview> => {
    return axios.get<ValidationOverview>(apiBaseUri + '/stats/validation-overview', {
        params: {
            'network-id': networkId,
            'start': dateToQueryParam(startDate),
            'end': dateToQueryParam(endDate)
        }
    }).then(response => response.data);
};

export const getDailyValidationHistory = (networkId: string, startDate: Date, endDate: Date): Promise<DailyValidationHistory> => {
    return axios.get<DailyValidationHistory>(apiBaseUri + '/stats/daily-validation-history', {
        params: {
            'network-id': networkId,
            'start': dateToQueryParam(startDate),
            'end': dateToQueryParam(endDate)
        }
    }).then(response => response.data);
};

export const getRevenueByProduct = (networkId: string, startDate: Date, endDate: Date): Promise<RevenueByProductData[]> => {
    return axios
        .get<RevenueByProduct>(apiBaseUri + '/stats/revenue-by-product', {
            params: {
                'network-id': networkId,
                'start': dateToQueryParam(startDate),
                'end': dateToQueryParam(endDate)
            }
        })
        .then(response => response.data as RevenueByProduct)
        .then(revenueByProduct => revenueByProduct.data)
};

export const getRevenueOverview = (networkId: string, startDate: Date, endDate: Date): Promise<RevenueOverview> => {
    return axios.get<RevenueOverview>(apiBaseUri + '/stats/revenue-overview', {
        params: {
            'network-id': networkId,
            'start': dateToQueryParam(startDate),
            'end': dateToQueryParam(endDate)
        }
    }).then(response => response.data);
}

export const getDailyRevenueHistory = (networkId: string, startDate: Date, endDate: Date): Promise<DailyRevenueHistory> => {
    return axios.get<DailyRevenueHistory>(apiBaseUri + '/stats/daily-revenue-history', {
        params: {
            'network-id': networkId,
            'start': dateToQueryParam(startDate),
            'end': dateToQueryParam(endDate)
        }
    }).then(response => response.data);
};

export const getAuthenticationLogs = (userId: string): Promise<AuthenticationLog[]> => {
    return axios.get<Page<AuthenticationLog>>(apiBaseUri + `/users/${userId}/authentication-logs`, {})
        .then(response => response.data.content);
}

export const getUsers = (email: string, limit: number): Promise<User[]> => {
    return axios.get<Page<User>>(apiBaseUri + `/users`, {
        params: {
            'email': email,
            'sort': 'createdAt,DESC',
            'size': limit
        }
    }).then(response => response.data.content);
}

export const getNetworkCatalog = (networkId: string): Promise<NetworkCatalog> => {
    return axios.get<NetworkCatalog>(apiBaseUri + `/networks/${networkId}/catalog`)
        .then(response => response.data);
}

export const getUserProfile = (userId: string): Promise<UserProfile> => {
    return axios.get<UserProfile>(apiBaseUri + `/users/${userId}/profile`)
        .then(response => response.data);
}

export const getTicketTemplateDetails = (ticketTemplateId: string): Promise<TicketTemplateDetails> => {
    return axios.get<TicketTemplateDetails>(apiBaseUri + `/ticket-templates/${ticketTemplateId}/details`)
        .then(response => response.data);
}

export const upsertTicketTemplateTitle = (ticketTemplateId: string, language: string, title: string) => {
    return axios.put(
        apiBaseUri + `/ticket-templates/${ticketTemplateId}/titles/${language}`,
        title,
        {
            headers: {
                'Content-Type': 'text/plain'
            },
        });
}

export const upsertTicketTemplateDescription = (ticketTemplateId: string, language: string, title: string) => {
    return axios.put(
        apiBaseUri + `/ticket-templates/${ticketTemplateId}/descriptions/${language}`,
        title,
        {
            headers: {
                'Content-Type': 'text/plain'
            },
        });
}

export const deleteTicketTemplateTitle = (ticketTemplateId: string, language: string) => {
    return axios.delete(apiBaseUri + `/ticket-templates/${ticketTemplateId}/titles/${language}`);
}

export const deleteTicketTemplateDescription = (ticketTemplateId: string, language: string) => {
    return axios.delete(apiBaseUri + `/ticket-templates/${ticketTemplateId}/descriptions/${language}`);
}

export const updateTicketTemplateState = (ticketTemplateId: string, state: TicketTemplateState) => {
    return axios.put(
        apiBaseUri + `/ticket-templates/${ticketTemplateId}/state`,
        state,
        {
            headers: {
                'Content-Type': 'text/plain'
            },
        })
}

export const updateTicketTemplateOrder = (ticketTemplateId: string, order: number) => {
    return axios.put(
        apiBaseUri + `/ticket-templates/${ticketTemplateId}/order`,
        order,
        {
            headers: {
                'Content-Type': 'text/plain'
            },
        })
}

export const updateTicketTemplatePrice = (ticketTemplateId: string, price: number) => {
    return axios.post(
        apiBaseUri + `/ticket-templates/${ticketTemplateId}/price-updates`,
        {
            price: price
        });
}

export const createTicketTemplateDurationHours =
    (networkId: string, productCode: number, hours: number, activationMode: TicketActivationMode): Promise<string> => {

        return axios.post(
            apiBaseUri + `/ticket-templates/duration-hours`,
            {
                networkId: networkId,
                productCode: productCode,
                hours: hours,
                activationMode: activationMode
            })
            .then(value => value.data['id']);
    };

export const createTicketTemplateTrips = (networkId: string, productCode: number, tripCount: number): Promise<string> => {

        return axios.post(
            apiBaseUri + `/ticket-templates/trips`,
            {
                networkId: networkId,
                productCode: productCode,
                trips: tripCount
            })
            .then(value => value.data['id']);
    };

export const buildAuthenticatedUser = (email: string, response: AxiosResponse): AuthenticatedUser => {
    if (response.status === 201) {
        const json = response.data as AuthResponseBody;

        const expiry = new Date();
        expiry.setSeconds(expiry.getSeconds() + json.expiresIn);

        return {
            userId: json.idUser,
            email: email,
            accessToken: json.accessToken,
            refreshToken: json.refreshToken,
            expiry: expiry
        };
    } else {
        throw new Error('Erreur serveur: ' + response.status);
    }
};

export const renew = (authenticatedUser: AuthenticatedUser): Promise<AuthenticatedUser> => {
    console.log(`Renewing token for user=${authenticatedUser.userId}...`);

    return axios.post(apiBaseUri + '/auth/renew', {}, {
        headers: {
            'Authorization': 'Bearer ' + authenticatedUser.refreshToken
        }
    }).then(response => buildAuthenticatedUser(authenticatedUser.email, response));
};

export const auth = (email: string, password: string): Promise<AuthenticatedUser> => {
    return axios.post(apiBaseUri + '/auth', {
        login: email,
        password: password
    }).then(response => buildAuthenticatedUser(email, response));
}
