import {Card} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import React, {FC, useEffect, useState} from "react";
import {DateRange} from "../../common/DateRangeSelect/DateRangeSelect";
import {getValidationOverview, ValidationOverview} from "../../../utils/api";
import {useTranslation} from "react-i18next";

const numberFormat = new Intl.NumberFormat('fr-FR');

interface ValidationOverviewProps {
    networkId: string
    dateRange: DateRange
}

export const ValidationOverviewCard: FC<ValidationOverviewProps> = ({networkId, dateRange}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [overview, setOverview] = useState<ValidationOverview>();

    useEffect(() => {
        setLoading(true);

        getValidationOverview(networkId, dateRange.startDate, dateRange.endDate)
            .then(value => setOverview(value))
            .then(() => setLoading(false))
            .catch(reason => console.error(reason));
    }, [networkId, dateRange]);

    return (
        <Card>
            <Card.Body>
                <div className="card-body">
                    <div className="row align-items-center gx-0">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">{t('validations')}</h6>
                            <span className="h2 mb-0">
                                {loading ? <Skeleton/> : overview ? numberFormat.format(overview.total) : '0'}
                            </span>
                        </div>
                        <div className="col-auto">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#8795A0"
                                 className="bi bi-phone-vibrate" viewBox="0 0 16 16">
                                <path
                                    d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/>
                                <path
                                    d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/>
                            </svg>

                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
