import React, {FC, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

export interface TicketTemplateDisplayOrderModalProps {
    show: boolean
    initialValue: number
    onCancel: () => void
    onConfirm: (inputs: Inputs) => void
}

export const hiddenDisplayOrderModal = (): TicketTemplateDisplayOrderModalProps => {
    return {
        show: false,
        initialValue: 1,
        onCancel: () => null,
        onConfirm: () => null
    }
}

export type Inputs = {
    displayOrder: number
}

export const TicketTemplateDisplayOrderModal: FC<TicketTemplateDisplayOrderModalProps> =
    ({show, initialValue, onCancel, onConfirm}) => {

        const {t} = useTranslation();
        const {register, handleSubmit, watch, formState: {errors}} = useForm<Inputs>();

        return (
            <Modal show={show} onHide={onCancel}>
                <form onSubmit={handleSubmit(onConfirm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('updateDisplayOrder')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            className="form-control"
                            type="number"
                            {...register("displayOrder", {value: initialValue, required: true,})}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            OK
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
