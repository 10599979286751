import {getAuthenticatedUser, removePersistedAuthenticatedUser, persistAuthenticatedUser} from "../utils/auth";
import {useState} from "react";
import {AuthenticatedUser} from "../utils/api";

export function useAuthenticatedUser() {
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser | null>(getAuthenticatedUser());

    const saveAuthenticatedUser = (authenticatedUser: AuthenticatedUser | null) => {
        if (authenticatedUser) {
            persistAuthenticatedUser(authenticatedUser);
        } else {
            removePersistedAuthenticatedUser();
        }

        setAuthenticatedUser(authenticatedUser);
    }

    return {
        authenticatedUser: authenticatedUser,
        setAuthenticatedUser: saveAuthenticatedUser
    };
}
