import {FC, FormEventHandler, useState} from "react";
import './LoginPage.css';
import {Form, Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {auth, AuthenticatedUser} from "../../utils/api";

interface Props {
    setAuthenticatedUser: (authenticatedUser: AuthenticatedUser) => void
}

export const LoginPage: FC<Props> = ({setAuthenticatedUser}) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<Error>();
    const {t} = useTranslation();

    const handleSubmit: FormEventHandler = async e => {
        e.preventDefault();

        try {
            const authenticatedUser = await auth(email, password);
            setAuthenticatedUser(authenticatedUser);
        } catch (e) {
            setError(e);
        }
    };

    return (
        <div className="container">
            <div className="row h-75">
                <div className="col-sm-12 my-auto">
                    <div className="w-25 mx-auto">
                        <div className="d-flex justify-content-center pt-3 mb-5">
                            <Image className="align-content-center" src="https://witick.io/_nuxt/img/logo.6add6c6.svg"/>
                        </div>

                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Control
                                    type="email"
                                    placeholder={t('emailAddress')}
                                    required
                                    autoFocus
                                    onChange={e => setEmail(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="password">
                                <Form.Control
                                    type="password"
                                    placeholder={t('password')}
                                    required
                                    onChange={e => setPassword(e.target.value)}/>
                            </Form.Group>

                            <button className="btn btn-primary btn-block" type="submit">{t('signIn')}</button>

                            {error ?
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div> : null}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
